import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toSentence',
    standalone: true
})
export class ToSentencePipe implements PipeTransform {
    public static toSentence(
        array: any[],
        separator: string = ', ',
        lastSeparator: string = ' and ',
        prefix: string = '',
        suffix: string = ''
    ): string {
        let last = '';

        if (!array?.length) {
            return '';
        }

        const wrapped: string[] = array.map((item: string) => {
            return prefix + item + suffix;
        });

        if (wrapped.length > 1) {
            if (wrapped.length > 2) {
                last += separator;
            }
            last += wrapped.splice(array.length - 2, 2).join(lastSeparator);
        }

        return wrapped.join(separator) + last;
    }

    public transform(
        array: any[],
        separator: string = ', ',
        lastSeparator: string = ' and ',
        prefix: string = '',
        suffix: string = ''
    ): string {
        return ToSentencePipe.toSentence(array, separator, lastSeparator, prefix, suffix);
    }
}
