import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { BannerFormatService } from '@core/services/api/bannerflow/bannerformat.service';
import { ObjectType } from '@shared/enums/objectType.enum';
import { BannerFormat } from '@shared/models/banner/bannerFormat.model';
import { ObjectPickerComponent } from '../objectPicker.component';
import { FormsModule } from '@angular/forms';
import { DebounceInputDirective } from '../../../directives/debounceInput.directive';
import { NgIf } from '@angular/common';
import { UIModule } from '@bannerflow/ui';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@Component({
    selector: 'bannerFormatPicker',
    templateUrl: 'bannerformat.picker.component.html',
    styleUrls: ['../objectPicker.component.scss'],
    standalone: true,
    imports: [FormsModule, DebounceInputDirective, NgIf, UIModule, NgxDatatableModule]
})
export class BannerFormatPickerComponent extends ObjectPickerComponent {
    @Input() private readonly bannerSetId: string;

    constructor(
        private readonly bannerFormatService: BannerFormatService,
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
        this.type = ObjectType.BannerFormat;

        this.tableRows = new Array<BannerFormat>();
    }

    protected async loadItems(): Promise<any> {
        this.loading = true;

        await this.bannerFormatService
            .getBannerFormats(this.bannerSetId)
            .then((bannerFormats: BannerFormat[]) => {
                this.tableRows = bannerFormats;
            });

        super.loadItems();
        this.datatable.recalculate();
        this.loading = false;
    }
}
