import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@config/app.config';
import { SessionService } from '@core/services/internal/session.service';
import { Localization } from '@shared/models/version/localization.model';

import { firstValueFrom, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { Language } from '@analytics/models/creative-set-models';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
    private localizations$: Observable<Localization[]>;

    constructor(
        private readonly http: HttpClient,
        private readonly sessionService: SessionService,
        private readonly authService: AuthService
    ) {}

    protected async setupHeaders(): Promise<HttpHeaders> {
        const token: string = await firstValueFrom(this.authService.getAccessTokenSilently());
        const headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        });

        return headers;
    }

    /**
     * Get localizations
     * @param useDemo Get demo localizations that are compliant with analytics demo-data
     */
    public getLocalizations(useDemo?: boolean): Observable<Localization[]> {
        const accountSlug = this.sessionService.user.account.slug;
        const brandSlug = this.sessionService.user.brand.slug;

        const localizationUrl = useDemo
            ? `${AppConfig.config.ACCOUNT_ACCESS_URL}/api/brand/current/${accountSlug}/${brandSlug}/localizations/demo`
            : `${AppConfig.config.ACCOUNT_ACCESS_URL}/api/brand/current/${accountSlug}/${brandSlug}/localizations`;
        if (!this.localizations$) {
            this.localizations$ = this.http.get(localizationUrl).pipe(
                map((data: any) =>
                    data.map((localization: Localization) =>
                        new Localization().deserialize(localization)
                    )
                ),
                shareReplay(1)
            );
        }

        return this.localizations$;
    }

    getLanguages(): Observable<Language[]> {
        return this.getLocalizations().pipe(
            map((localizations: Localization[]) =>
                localizations.map((localization: Localization) => ({
                    id: localization.id,
                    flag: localization.culture.cultureCode,
                    versionName: localization.name,
                    targetUrl: localization.targetUrl,
                    mainLocalization: localization.mainLocalization
                }))
            )
        );
    }
}
